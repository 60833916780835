import * as React from "react";

import "@fontsource/dm-sans/400.css";
import "@fontsource/dm-sans/500.css";
import {
  Anchor,
  Box,
  Heading,
  Paragraph,
  Text,
} from "grommet";
import Seo from "../components/seo";
import Layout from "../components/layout.js";
import Foot from "../components/foot.js";
import Mailchimp from "../components/mailchimp.js";
import "../static/assets/styles.css";

// markup
export default function Join(data) {
  return (
    <Layout>
      <Seo title="Join twb" />
        <Box
          justify="center"
          gap="medium"
          width="large"
          alignSelf="center"
          margin={{ vertical: "large"}}
        >
          <Box background="dark-1" round="xsmall" pad="medium">
            <Heading level={1} size="small">
              Join the community{" "}
            </Heading>
            <Text>You'll get:</Text>
            <Box as="ul">
              <Text as="li">early access tickets and events</Text>
              <Text as="li">
                access to Slack, where you can ask questions and share ideas
                with the community
              </Text>
              <Text as="li">
                13+ hours of free content from previous events
              </Text>
              <Text as="li">
                Access to our newsletter (you can unsubscribe at anytime)
              </Text>
            </Box>
            <Mailchimp/>
          </Box>

          <Box background="dark-1" round="xsmall" pad="medium">
            <Heading level={3} size="xsmall">
              Sponsor a project or event
            </Heading>
            <Paragraph margin={{ top: "none" }}>
              Speak to our team about supporting a project by emailing{" "}
              <Anchor href="mailto:sponsors@togetherwerebetter.org.uk">
                sponsors@togetherwerebetter.org.uk
              </Anchor>
            </Paragraph>
          </Box>
          <Box background="dark-1" round="xsmall" pad="medium">
            <Heading level={3} size="xsmall" margin={{ bottom: "none" }}>
              Get updates
            </Heading>
            <Box as="ul">
              <Text as="li">
                <Anchor href="https://twitter.com/twb_hq">
                  Follow @twb_hq on Twitter
                </Anchor>
              </Text>
            </Box>
          </Box>
        </Box>
        <Foot/>
    </Layout>
  );
}
